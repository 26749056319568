import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useAuthToken from '../hooks/useAuthToken';
import LoadingView from 'views/LoadingView';

interface Auth0ProtectedRouteProps {
  children: JSX.Element;
}

export default function Auth0ProtectedRoute(props: Auth0ProtectedRouteProps): JSX.Element {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const hasToken = useAuthToken();

  console.log('isAuthenticated: ', isAuthenticated);
  console.log('isLoading: ', isLoading);
  console.log('hasToken: ', hasToken);

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }

    (async (): Promise<void> => {
      console.log('loginWithRedirect');
      await loginWithRedirect({
        authorizationParams: {
          redirect_uri: `${window.location.origin}/react-callback`,
        },
      });
    })().catch((e) => console.error(e));
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return isAuthenticated && hasToken ? props.children : <LoadingView />;
}
